import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Documentation } from '../documentation'

const Do = () => {
	return (
		<L10nProvider>
			<Documentation />
		</L10nProvider>
	)
}
export default Do;
